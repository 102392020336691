export const SCOPE_TYPE = {
  REP_ACCESS: 'rep_access',
  TREMFYA_GI: 'tremfya_gi',
  DERMS: 'derms',
  NEURO_SCIENCE: 'neuro_science',
  HCP: 'hcp',
  GENERAL: 'general',
  COPILOT: 'copilot',
};

export const DIVISION_SCOPE = {
  SD: 'derms',
  DO: 'derms',
  SA: 'tremfya_gi',
  SG: 'tremfya_gi',
  SR: 'rep_access',
  RM: 'rep_access',
  OI: 'general hcp',
  TD: 'neuro_science',
  KS: 'neuro_science',
  PS: 'neuro_science',
  KM: 'neuro_science',
};
